import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'

const StartNextProject = () => {
  return (
    <Layout>
		<Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/start-your-next-project" />
		  <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Start Your Next Project" description='eWise - Start Your Next Project'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24 overflow-hidden min-h-screen flex items-center">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap">
                    <div className="order-2 sm:order-1 left_banner w-full px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="text-center max-w-1000 m-auto pt-10">
                                <h1 className=" font-normal relative pb-2"> <strong className="text-40 md:text-40 lg:text-40 xl:text-40 2xl:text-50">Start Your Next Project</strong></h1>
                                <div className="text">
                                    <p className="mb-7">We are not just a recognized IT company but also a group of self-motivated and talented experts that help brands including enterprises, mid-size businesses and start-ups with innovative web development services.</p>
                                </div>
                            </div>
							<div className="start_your_project flex justify-center">
								<div className="form max-w-1000 bg-white pb-20 pt-9 2xl:pt-12 px-2 sm:px-4 xl:px-6 2xl:px-8 shadow-contactform relative mb-11">
									<form action="" className="flex flex-wrap">
										<div className="input-group mb-6 2xl:mb-8 w-full md:w-1/2 px-4">
											<input className="w-full border-border-menu py-1 2xl:py-1 px-4 border outline-none text-sm 2xl:text-base bg-transparent" type="text" placeholder="Name" value=""/>
										</div>
										<div className="input-group mb-6 2xl:mb-8 w-full md:w-1/2 px-4">
											<input className="w-full border-border-menu py-1 2xl:py-1 px-4 border outline-none text-sm 2xl:text-base bg-transparent" type="email" placeholder="Email" value=""/>
										</div>
										<div className="input-group mb-6 2xl:mb-8 w-full md:w-1/2 px-4">
											<input className="w-full border-border-menu py-1 2xl:py-1 px-4 border outline-none text-sm 2xl:text-base bg-transparent" type="tel" placeholder="Phone" value=""/>
										</div>
										<div className="input-group mb-6 2xl:mb-8 w-full md:w-1/2 px-4">
											<select className="h-full w-full text-border-menu border-border-menu py-1 2xl:py-1 px-4 border outline-none text-sm 2xl:text-base bg-transparent" name="cars" id="cars" form="carform">
												<option className="text-sm 2xl:text-base text-border-menu" value="volvo">Volvo</option>
												<option className="text-sm 2xl:text-base text-border-menu" value="saab">Saab</option>
												<option className="text-sm 2xl:text-base text-border-menu" value="opel">Opel</option>
												<option className="text-sm 2xl:text-base text-border-menu" value="audi">Audi</option>
											  </select>
										</div>
										<div className="input-group mb-6 2xl:mb-8 w-full md:w-1/2 px-4 flex flex-wrap">
											<div className="form-check">
												<input type="radio" name="radio_group" id="Option1" className="radio_input" checked />
												<label className="radio_label cursor-pointer" htmlFor="Option1">
												  Option 1
												</label>
											</div>
											<div className="form-check ml-8">
												<input type="radio" name="radio_group" id="Option2" className="radio_input"/>
												<label className="radio_label cursor-pointer" htmlFor="Option2">
												  Option 2
												</label>
											</div>
										</div>
										<div className="input-group w-full px-4">
											<textarea className="max-h-100 w-full border-border-menu py-1 px-4 border outline-none text-base bg-transparent" name="" id="" cols="30" rows="10" placeholder="Message"></textarea>
										</div>
										<div className="input-group">
											<input className="font-semibold cursor-pointer bg-pink w-20 h-20 2xl:w-90 2xl:h-90 rounded-full absolute left-1/2 bottom-0 transform text-white capitalize -translate-x-1/2 translate-y-1/2" type="submit" value="submit"/>
										</div>
									</form>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>

    </Layout>
  )
}

export default StartNextProject